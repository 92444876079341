import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { GetStaticProps } from "next";
import clsx from "clsx";
import { useEffect } from "react";
import Head from "next/head";

import { getAnalytics } from "@/utils/analytics";
import Link from "@/components/hoc/CustomLink";
import { geti18nNamespaces } from "@/utils/i18n";

/**
 * Renders the 404 page.
 *
 * Can be rendered as a React component. In that case make sure to load
 * the "404" i18n namespace when rendering the parent page.
 */
export default function Custom404() {
  const router = useRouter();
  const { t } = useTranslation("404");

  useEffect(() => {
    if (typeof window !== "undefined") {
      getAnalytics()?.track("404 Reached", {
        url: router.asPath,
      });
    }
  }, [router.asPath]);

  return (
    <div className="min-h-screen h-screen relative">
      <Head>
        <title>{t("404.error")} - Pianity</title>
      </Head>
      <div
        className={clsx(
          "absolute w-screen h-full top-0 left-0 -z-5",
          "bg-dark-3 bg-dark-bg-mobile lg:bg-dark-bg"
        )}
      />
      <div className="container flex pt-28 sm:pt-0 items-end h-full w-full lg:pb-0 rounded-md">
        <div
          className={clsx(
            "w-full h-full px-10 lg:px-0 lg:h-auto flex flex-col",
            "lg:flex-row lg:items-end justify-center lg:justify-between",
            "lg:mb-20"
          )}
        >
          <div className="flex flex-col">
            <div className={"text-60 lg:text-7xl text-white font-medium"}>{t("404.title")}</div>
            <div
              className={clsx(
                "text-20 lg:text-22 leading-0.75 font-medium tracking-wider",
                "text-purple-3 mt-8 lg:mt-20 mb-1 lg:mb-2"
              )}
            >
              {t("404.error")}
            </div>
            <div className={"text-lg lg:text-2xl text-white"}>{t("404.text")}</div>
          </div>
          <div className="text-white flex flex-col lg:flex-row lg:items-end mt-14 sm:mt-0">
            <div className="flex flex-col mb-6 lg:mb-0 lg:mr-6">
              <div className={"text-20 lg:text-22 font-medium"}>{t("404.cta.fan.title")}</div>
              <Link href="/connect">
                <a
                  className={clsx(
                    "text-sm lg:text-base mt-3 lg:mt-6",
                    "transition ease-quart-out duration-300",
                    "relative before:bg-gradient-selected",
                    "before:border-3 before:border-gradient before:rounded-full",
                    "md:hover:shadow-button rounded-full px-3 h-10 lg:px-5",
                    "lg:h-12 flex items-center w-max uppercase font-medium",
                    "tracking-wider cursor-pointer"
                  )}
                >
                  {t("404.cta.fan.button")}
                </a>
              </Link>
            </div>
            <div className="flex flex-col">
              <div className={"text-20 lg:text-22 font-medium"}>{t("404.cta.artist.title")}</div>
              <Link href="/artists/register">
                <a
                  className={clsx(
                    "text-white text-sm lg:text-base mt-3 lg:mt-6 transition",
                    "ease-quart-out duration-300 bg-gradient-selected",
                    "md:hover:shadow-button rounded-full px-3 h-10 lg:px-5",
                    "lg:h-12 flex items-center w-max uppercase font-medium",
                    "tracking-wider cursor-pointer"
                  )}
                >
                  {t("404.cta.artist.button")}
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", geti18nNamespaces(["404"]))),
    },
  };
};
